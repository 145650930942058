<template>
  <div class="p-fluid">
    <div class="p-col-12 p-lg-12">
      <div class="card card-w-title">
        <div class="row manager-title-row">
          <div class="p-col-4 manager-title-custom">{{ title }}</div>
        </div>
        <!-- <div class="note">(*) Nội dung không được bỏ trống</div> -->
        <formEdit :key="componentKey" :mode="mode" @back="backToList" @save="saveData" :triggerSave.sync="triggerSave"
          :model="models" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import DataServices from '@/core/DataServices'
import formEdit from './add'
import gql from "graphql-tag";
export default {
  components: {
    formEdit
  },
  data() {
    return {
      users: null,
      validationErrors: [],
      title: '',
      mode: 'add',
      models: {},
      componentKey: 0,
      accountID: '',
      page_transaction: null,
      active_check: true,
      users_clubs_id: '',
      time_zone_local: moment.tz.guess(true),
      triggerSave: false
    }
  },
  async mounted() {
    console.log("this.$route.name:", this.$route.query.page_transaction);
    this.mode = this.$route.name === 'edit' ? 'edit' : this.mode;

    if (this.$route.params.id !== undefined) await this.getData(this.$route.params.id);
    if (!this.$commonFuction.isEmpty(this.$route.query)) {
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)) {
        this.page_transaction = this.$route.query.page_transaction;
      }
    }
    //check quyền
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_TERM_OF_USE_POLICY_CHIL);
    if (this.mode == 'edit') {
      if (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0) {
        this.$router.push({ path: this.$constants.URL_ERR.FOBIDDEN });
      }
    } else {
      if (arr_role.indexOf(this.$constants.ROLE.ADD) < 0) {
        this.$router.push({ path: this.$constants.URL_ERR.FOBIDDEN });
      }
    }
  },
  methods: {
    backToList() {
      this.$router.push({ path: '/admin/list-term_of_use' });
    },
    UpdateActive(key) {
      // console.log("key",key)
      var uid = this.$route.params.id;
      this.$commonFuction.is_loading(true);
      if (key == 'lock') {
        this.active_check = false
      } else {
        this.active_check = true
      }

      let variables = {
        'data_update': {
          'active': this.active_check
        },
        'where_key': {
          'id': { '_eq': this.$route.params.id }
        }
      };
      var that = this;
      this.$apollo.mutate({
        mutation: DataServices.updateData('users'),
        variables: variables
      }).then(async (res) => {
        console.log('Success', res);
        if (key == 'lock') {
          var query_t = `mutation {
            delete_jwt_token(where: { uid: {_eq: "${uid}"}}) {
              returning {
                id
              }
            }
          }`
          await that.$apollo.mutate({
            mutation: gql(query_t),
          })
        }
        that.$toast.add({ severity: 'success', summary: 'Success', detail: 'Save data successfully', life: 3000 })
      })
      this.$commonFuction.is_loading(false);
      // console.log("this.$route.params.id",this.$route.params.id)
    },
    async saveData(params) {
      this.$commonFuction.is_loading(true);
      this.triggerSave = false;

      try {
        // Cập nhật `term_of_use`
        await this.$apollo.mutate({
          mutation: DataServices.updateData('term_of_use'),
          variables: {
            data_update: {
              updated_at: moment.tz(new Date(), this.time_zone_local),
              updated_by: this.$store.getters.user,
            },
            where_key: {
              id: { _eq: this.models.id }
            }
          }
        });

        // Cập nhật `term_of_use_lang`
        for (let lang of params.list_use_lang) {
          if (lang.id) {
            console.log('id lang là: ' + lang.id);
            // Nếu `id` tồn tại, thực hiện cập nhật bản ghi hiện tại
            await this.$apollo.mutate({
              mutation: DataServices.updateData('term_of_use_lang'), // Đúng mutation
              variables: {
                data_update: {
                  content: lang.content,
                  id_lang: lang.id_lang,
                  rterm_of_use_id: lang.rterm_of_use_id,
                },
                where_key: {
                  id: { _eq: lang.id }
                }
              }
            });
          } else {
            console.error('Lang ID is missing for:', lang);
          }
        }

        this.redirectEdit();

      } catch (error) {
        console.log('error', error);
        this.$toast.add({
          severity: 'error',
          summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
          detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
          life: 3000
        });
      } finally {
        this.$commonFuction.is_loading(false);
      }
    },

    async redirectEdit() {
      var that = this;
      var router_back = { path: '/admin/list-term_of_use' };
      that.$commonFuction.is_loading(false);
      if (that.mode == 'add') {
        that.$router.push(router_back);
      } else {
        if (!that.$commonFuction.isEmpty(router_back)) {
          // that.page_transaction = ""+that.page_transaction
          router_back.query = { page_transaction: that.page_transaction.toString() };
        }
        that.$router.push(router_back);
      }
      that.$toast.add({ severity: 'success', summary: 'Success', detail: 'Save data successfully', life: 3000 })
    },
    async getData(id) {
      var { data } = await this.$apollo.query({
        query: DataServices.getList('term_of_use'),
        variables: {
          where_key: {
            id: { '_eq': id }
          }
        },
        fetchPolicy: "network-only"
      })
      console.log("datatatatata", data.term_of_use[0].name)
      this.title = this.mode === 'edit' ? data.term_of_use[0].name : 'edit';

      // console.log("datadata:", data);
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
      }
      this.componentKey++;
    },
  },
  apollo: {

  }
}
</script>

<style lang="scss">
.manager-title-custom {
  color: #1D3118;
  font-size: 24px;
  /* margin-bottom: 16px; */
  padding-bottom: 10px;
  /* border-bottom: 1px solid #EEEEEE; */
  font-weight: 700;
  margin-left: 20px;
}

.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only {
      padding: 0 !important;
    }
  }
}

.note {
  letter-spacing: 0px;
  color: #707070;
  margin-bottom: 24px;
  padding-top: 10px;
}

.p-formgrid {
  margin: 0;

  .p-panel-header {
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    background: #EEEEEE;
  }

  .p-card-field {
    padding: 24px;

    .p-field {
      .p-col-fixed {
        width: 300px;
        display: inline-block !important;
        margin: 0;
      }

      .p-col-width {
        width: 500px !important;
        border-radius: 5px;

        &.disabled {
          background: #EEEEEE;
          border: 0 none;
          padding: 8px 10px;
        }

        .file-select {
          margin: 0;

          input[type="file"] {
            display: none;
          }

          .select-button {
            display: inline-block;
            cursor: pointer;
            background: transparent;
            border-radius: 5px;
            color: #000000;
            font-size: 13px;
            padding: 3px 16px;
            border: 1px solid #B4B4B4;
          }
        }

        &.pac-target-input {
          height: 80px;
          word-break: break-all;
          overflow-wrap: break-word;
          resize: none;
        }
      }

      .box-images {
        margin: 0;
        margin-top: 10px;
        position: relative;

        .item-img {
          width: 150px;
          height: 150px;
          margin-right: 8px;
          margin-bottom: 8px;
          display: inline-flex;
          position: relative;

          img {
            max-width: 100%;
            height: auto;
            margin: 0 auto;
            object-fit: contain;
            padding: 5px;
          }

          .button-clear {
            border-radius: 10px;
            width: 24px;
            height: 24px;
            background: #F5564E;
            float: right;
            cursor: pointer;
            position: absolute;
            right: 0px;

            img {
              vertical-align: top;
            }
          }
        }
      }

      .p-invalid {
        display: block;
      }
    }
  }

  .fixed-bottom-toolbar {
    background: transparent;
    border: 0 none;
    margin-top: 22px;
    border-top: 1px solid #B4B4B4;
    padding: 0;
    padding-top: 30px;

    .p-button {
      width: auto;
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.manager-title-row {
  border-bottom: 1px solid #EEEEEE;
}

.p-toolbar-group-right {
  flex-basis: 30%;
}

.p-button-warning.p-button {
  margin: 0 10px;
}
</style>